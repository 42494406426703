import styled from 'styled-components';

export const AboutHeader = styled.div`
  padding: 100px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palatte.background.contrast};

  & > h1 {
    font-size: 6rem;
    line-height: 1.6;

    & > span {
      height: 86px;
      background-color: ${({ theme }) => theme.palatte.text.default};
      padding: 1rem 2rem;
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 4rem;
      line-height: 1.6;
    }
  }

  @media (max-width: 576px) {
    & > h1 {
      font-size: 3rem;
      line-height: 1.7;
    }
  }
`

export const AboutDetails = styled.div`
  max-width: 1400px;
  margin: 120px auto;

  & > div {
    display: flex;

    & > div:first-child {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      width: 600px;

      h1 {
        font-weight: 500;
        font-size: 3rem;
      }
    }
    & > div:last-child {
      display: flex;
      justify-content: center;
      padding: 24px;
      width: 800px;
      flex-direction: column;

      & > h2 {
        font-weight: 600;
      }
      & > p {
        line-height: 1.625em;
      }
    }
  }

  @media (max-width: 768px) {
    & > div {
      flex-direction: column;

      & > div {
        width: auto !important;
      }

    }
  }

`;

export const Values = styled.div`
  max-width: 1200px;
  padding: 100px -50px;
  margin: auto;
  display: flex;
  
  & > div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto 50px;
    
    & > .image {
      width: 500px;
      height: 500px;
      box-shadow: -10px 8px 0px 0px ${({ theme }) => theme.palatte.background.highlight};
      border: 1px solid ${({ theme }) => theme.palatte.text.default};
    }
    & > .image > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > h2 {
      font-weight: 600;
    }
    & > p {
      line-height: 1.6;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 8px;

    & > div {
      margin: auto 8px;
      width: auto;
      
      & > .image {
        width: calc(100vw - 24px);
        height: auto !important;
      }
    }
  }
`

export const NotFoundWrapper = styled.div`
  height: 400px;
  margin-top: 100px;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  & > h1 {
    font-size: 200px;
    margin-bottom: 0px;
    font-family: Thicccboi, Open Sans, sans-serif;
  }

  & > p {
    font-size: 18px;
  }
`